<template>
  <div>
    <h5 class="pl-1 font-weight-bold my-2">Other Training</h5>
    <table class="table table-sm table-bordered small">
      <thead>
      <tr>
        <th class="align-middle text-center"  style="width: 7%">Code</th>
        <th class="align-middle text-center">Name</th>
        <th class="align-middle text-center">Document Number</th>
        <th class="align-middle text-center">Issued Date</th>
        <th class="align-middle text-center">Expiry Date</th>
        <th class="align-middle text-center">Days to Expire</th>
        <th class="align-middle text-center">Action</th>
      </tr>
      </thead>
      <tbody>
      <template v-if="crewDocuments">
        <tr v-for="document in crewDocuments" :class="{
                          'bg-danger': (document.crew_document && document.crew_document.expired),
                          'bg-primary': (!!document.crew_document && document.crew_document.monthRemaining <= 12 && document.crew_document.monthRemaining > 6 && passportDocIds.includes(document.id)),
                          'bg-yellow': (!!document.crew_document && document.crew_document.monthRemaining <= 6 && !document.crew_document.expired && passportDocIds.includes(document.id)),
                      }">
          <td> <span>{{document.code ? document.code.toUpperCase() : ''}}</span></td>
          <td>
            <span>{{document.name ? document.name.toUpperCase() : ''}}</span>
            <span v-if="document.crew_document" class="text-primary font-weight-bolder">
                    {{extractNoOfExtension(document.crew_document) }}
                </span>
            <span v-if="document.crew_document" class="text-primary font-weight-bolder">
                  <template v-if="document.crew_document.extendedExpiryDate">
                      / Orig Cont. Date ({{formatEliteDate(document.crew_document.issued_date)}})
                  </template>
                </span>
          </td>
          <template v-if="document.crew_document">
            <td>{{document.crew_document.doc_no}}</td>
            <td class="text-center">{{formatEliteDate(document.crew_document.issued_date)}}</td>
            <td class="text-center">{{formatEliteDate(document.crew_document.expiry_date)}}</td>
            <td class="text-center">{{document.crew_document.daysToExpire ? document.crew_document.daysToExpire.toUpperCase() : ''}}</td>
          </template>
          <template v-else>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </template>
          <td class="text-center">
            <button type="button"
                    :disabled="!document.crew_document"
                    style="cursor: pointer"
                    class="btn btn-secondary btn-xs"
                    data-toggle="modal"
                    data-target="#crewDocument"
                    @click="viewCrewDocument(document)">
              View
            </button>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="crewDocument" tabindex="-1" aria-labelledby="crewDocument" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" style="height:auto">
          <div class="modal-header">
            <h5 class="modal-title" id="crewIncidentBehaviorTitle">VIEW <strong>{{documentName}}</strong></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetCrewDocument">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-4">
                <table class="w-100">
                  <tr>
                    <td>Doc No:</td>
                    <td class="font-weight-bold">{{crewDocument ? crewDocument.doc_no : ''}}</td>
                  </tr>
                  <tr>
                    <td>Issued Date:</td>
                    <td class="font-weight-bold">{{crewDocument ? formatEliteDate(crewDocument.issued_date) : ''}}</td>
                  </tr>
                  <tr>
                    <td>Expiry Date:</td>
                    <td class="font-weight-bold">{{crewDocument ? formatEliteDate(crewDocument.expiry_date) : ''}}</td>
                  </tr>
                </table>
                <hr>
                <h6 class="font-weight-bold" id="crewIncidentBehaviorTitle">Document History</h6>
                <table class="table table-sm table-bordered small">
                  <thead>
                  <tr>
                    <td class="align-middle text-center font-weight-bold">ISSUED DATE</td>
                    <td class="align-middle text-center font-weight-bold">EXPIRY DATE</td>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="crewDocumentHistory">
                    <tr v-for="history in crewDocumentHistory" :class="{
                        'selected' : crewDocument.id === history.id
                      }" @click="getSelected(history)"  style="cursor: pointer">
                      <td class="text-center font-weight-bold" :class="{'text-white' : crewDocument.id === history.id }">{{history.issued_date ? formatEliteDate(history.issued_date) : 'N/A' }}</td>
                      <td class="text-center font-weight-bold" :class="{'text-white' : crewDocument.id === history.id }">{{history.expiry_date ? formatEliteDate(history.expiry_date) : 'N/A' }}</td>
                    </tr>
                  </template>

                  </tbody>
                </table>
              </div>
              <div class="col-8" style="height: 77vh">
                <template v-if="crewDocumentFile">
                  <iframe style="height: inherit; width: 100%" :src="crewDocumentFile"></iframe>
                </template>
                <div v-else class="alert alert-danger">
                  NO FILE FOUND
                </div>

              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="resetCrewDocument">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DateService} from "@/services/DateService";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";

export default {
  name: "Licences",
  data(){
    return {
      cms_doc_type_id : 2,
      crewDocument:{},
      documentName:'',
      crewDocumentId:null,
      crewDocumentHistory:{},
      crewDocumentFile:false,
      passportDocIds: [
        2,	    // PHILIPPINE PASSPORT
        37,	    // CHINESE PASSPORT
        39,	    // BULGARIAN PASSPORT
        41,	    // UKRANIAN PASSPORT
        76,	    // JAPANESE PASSPORT
        106,    // MALAYSIAN PASSPORT
        109,    // LIBERIAN PASSPORT
      ],
    }
  },
  methods:{
    ...mapActions([
      'getCrewDocuments'
    ]),
    extractNoOfExtension(crewDocument){
      if(crewDocument != null){
        if(crewDocument.hasOwnProperty('noOfExtensions')){
          return crewDocument.noOfExtensions ?
            'with '+crewDocument.noOfExtensions+' extensions' : '';
        }
      }
      else{
        return '';
      }
    },
    formatEliteDate(date){
      return DateService.dateEliteFormat(date);
    },
    async viewCrewDocument(crewDocument){
      this.crewDocument=Object.assign({},crewDocument.crew_document ?? {id:null,issued_date : null,expiry_date : null,doc_no:null});
      this.documentName=crewDocument.name
      const crewDocumentHistory= await CrewService.getCrewDocumentHistoryFromCms({document:crewDocument.id,cms_id:this.$route.params.id});
      const crewDocumentFile= await CrewService.getCrewDocumentFileFromCms({cms_crew_document_id: this.crewDocument.id});
      this.crewDocumentFile=crewDocumentFile ? FileService.base64FileToBlobUrl(crewDocumentFile.file) : false;
      this.crewDocumentHistory= Object.assign({},crewDocumentHistory);
    },
    async getSelected(crewDocument){
      this.crewDocument=Object.assign({},crewDocument ?? {id:null,issued_date : null,expiry_date : null,doc_no:null});
      const crewDocumentFile= await CrewService.getCrewDocumentFileFromCms({cms_crew_document_id: crewDocument.id});
      this.crewDocumentFile=crewDocumentFile ? FileService.base64FileToBlobUrl(crewDocumentFile.file) : false;
    },

    resetCrewDocument(){
      this.crewDocument={}
      this.documentName=''
      this.crewDocumentId=null
      this.crewDocumentHistory={}
      this.crewDocumentFile=false
    }
  },
  computed:{
    ...mapGetters([
      'crewDocuments',
    ])
  },

  async created() {
    const params= {
      cms_id: this.$route.params.id,
      cms_doc_type_id : this.cms_doc_type_id
    }

    await this.getCrewDocuments(params)
  },


}
</script>

<style scoped>
.selected{
  background-color: #e87c87 !important;
  color: white !important;
}
tr.bg-danger td {
  color: white !important;
}

tr.bg-danger td span {
  color: white !important;
}
</style>
